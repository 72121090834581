import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../layouts'

import '../../scss/about.scss'

import partners from "../../data/partners.yaml"


const Consortium = ({ data }) => {
  console.log(partners)

  return (
    <Layout>
      <div class="about">
        <div className="container">
          <div className="about-content">
            <div className="logo-wrapper">
              {/* <img className="logo-small"
                alt={"DigiWells logo"}
                src="/digiwells_logo.png" /> */}
              {
                partners.researchPartners &&
                partners.researchPartners.map(function (partner) {
                  return (
                    // <a href={partner.url}>
                    <img className="logo-small"
                      alt={partner.company + " logo"}
                      src={partner.logo} />
                    // </a>
                  )
                })
              }
              {
                partners.industrialPartners &&
                partners.industrialPartners.sort((a, b) => a.company.localeCompare(b.company)).map(function (partner) {
                  return (
                    // <a href={partner.url}>
                    <img className="logo-small"
                      alt={partner.company + " logo"}
                      src={partner.logo} />
                    // </a>
                  )
                })
              }
              {
                partners.industrialPartnersInKind &&
                partners.industrialPartnersInKind.sort((a, b) => a.company.localeCompare(b.company)).map(function (partner) {
                  return (
                    // <a href={partner.url}>
                    <img className="logo-small"
                      alt={partner.company + " logo"}
                      src={partner.logo} />
                    // </a>
                  )
                })
              }
              {
                partners.international &&
                partners.international.sort((a, b) => a.company.localeCompare(b.company)).map(function (partner) {
                  return (
                    // <a href={partner.url}>
                    <img className="logo-small"
                      alt={partner.company + " logo"}
                      src={partner.logo} />
                    // </a>
                  )
                })
              }
              {
                partners.govermentPartners &&
                partners.govermentPartners.map(function (partner) {
                  return (
                    // <a href={partner.url}>
                    <img className="logo-small"
                      alt={partner.company + " logo"}
                      src={partner.logo} />
                    // </a>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Consortium
